import Player from '@vimeo/player';
import gsap from 'gsap';

const videos = Array.from(document.querySelectorAll('.js-video'));
let currentPlaying = null;

function newYouTubePlayer(clicked) {
  // Define onPlayerReady function in here so it's scoped to the player
  function onPlayerReady(event) {
    const container = clicked.closest('.js-video');
    container.classList.add('video-is-clicked');
    event.target.playVideo();
  }
  // Build videoId and initialisation options
  let start = 0;
  let videoId = null;
  const url = clicked.href;
  // Get querystring portion of URL
  const qs = url.split('?')[1];
  // Split querystring into params
  const params = new URLSearchParams(qs);
  start = params.get('t');
  videoId = params.get('v');
  // Youtube replaces whatever DOM element you pass in, so append an empty container div
  const container = document.createElement('div');
  clicked.append(container);
  // Construct the new player
  const player = new YT.Player(container, {
    origin: window.location.origin,
    height: clicked.offsetHeight,
    width: clicked.offsetWidth,
    videoId: videoId,
    playerVars: {
      start: start
    },
    events: {
      'onReady': onPlayerReady,
      'onStateChange': function(event) {
        // If another video on the page is already playing, pause it to prevent multiple videos playing at the same time
        if (event.data === YT.PlayerState.PLAYING) {
          if (currentPlaying !== null && currentPlaying.videoId !== videoId) {
            currentPlaying.player.pauseVideo();
          }
          currentPlaying = {
            videoId: videoId,
            player: player
          };
        }
      }
    }
  });
}

function initYouTubeIframeAPI(clicked) {
  window.onYouTubeIframeAPIReady = function() {
    newYouTubePlayer(clicked);
  }
}

function isYouTube(url) {
  return url.includes('watch?v=') || url.includes('.be/');
}

function isVimeo(url) {
  return url.includes('vimeo');
}

function initYouTubeVideo(e) {
  e.preventDefault();
  // Check youtube URL format
  const clicked = e.currentTarget;
  const videoInitialised = clicked.dataset.init;
  if (videoInitialised === 'true') {
    return;
  } else {
    clicked.dataset.init = 'true';
  }
  // Check if youtube script is already on the page
  if (window.YT) {
    newYouTubePlayer(clicked);
    return;
  }
  // If not, add it..
  initYouTubeIframeAPI(clicked);
  // Load the IFrame Player API code asynchronously.
  const tag = document.createElement('script');
  tag.src = "https://www.youtube.com/iframe_api";
  tag.id = 'youtube-script';
  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  // This triggers the global onYouTubeIframeAPIReady
}

function initVimeoVideo(e) {
  e.preventDefault();
  const clicked = e.currentTarget;
  const url = clicked.href;
  const id = clicked.id;
  const width = clicked.offsetWidth;
  const height = clicked.offsetHeight;
  const player = new Player(id,  {
    url: url,
    width: width,
    height: height,
    transparent: false
  });
  player.ready().then(function() {
    const container = clicked.closest('.js-video');
    container.classList.add('video-is-clicked');
    player.play();
  });
  Promise.all([player.getVideoWidth(), player.getVideoHeight()]).then(function(dimensions) {
    const originWidth = dimensions[0];
    const originHeight = dimensions[1];
    const ratio = originHeight/originWidth;
    gsap.to(clicked, {
      height: width*ratio,
      duration: 0.5,
      onStart: () => {
        clicked.classList.add('vimeo-ratio-override');
      }
    });
  });
}

function checkVideoType(e) {
  const clicked = e.currentTarget;
  const url = clicked.href;
  if (isYouTube(url)) {
    initYouTubeVideo(e);
  } else if (isVimeo(url)) {
    initVimeoVideo(e);
  }
}

export default function() {
  videos.forEach(video => {
    video.addEventListener('click', checkVideoType);
  })
}
