import gsap from 'gsap';
import { search } from './search';

const button = document.getElementById('mobile-nav-button');
const body = document.body;
const activeClass = 'mobile-nav-is-active';
const nav = document.getElementById('mobile-nav');

function initResizeListener() {
  // Reference: http://www.html5rocks.com/en/tutorials/speed/animations/
  let ticking = false;

  window.addEventListener('resize', function(e) {
    if (!ticking) {
      window.requestAnimationFrame(function() {
        check();
        ticking = false;
      });

      ticking = true;
    }
  });
}

function check() {
  if (window.innerWidth >= 960) {
    hide();
  }
}

function hide() {
  body.classList.remove(activeClass);
  gsap.to(nav, {
    xPercent: -100,
    duration: 0.1,
    ease: 'none'
  })
}

function show() {
  body.classList.add(activeClass);
  gsap.to(nav, {
    xPercent: 100,
    duration: 0.1,
  })
}

function toggle() {
  if (body.classList.contains(activeClass)) {
    hide();
  } else {
    search.hide();
    show();
  }
}

function init() {
  button.addEventListener('click', toggle);
  initResizeListener();
}

const mobile = {
  init: init,
  hide: hide
}

export { mobile };
