import gsap from 'gsap';
import axios from 'axios';
import { dropdown } from './dropdown';
import { mobile } from './mobile';

const activeClass = 'search-bar-is-active';
const bar = document.getElementById('search-bar');
const input = document.getElementById('search-form-input');
const body = document.body;

// function submit(e) {
//   const form = e.target.closest('#search-form');
//   if (!form) {
//     return;
//   }
//   // TODO: If time, convert to ajax
//   e.preventDefault();
//   // Get search input
//   const input = document.getElementById('search-form-input');
//   const value = input.value;
//   const url = new URL(form.action);
//   url.searchParams.append('q', value);
//   axios.get(url).then(res => {
//     const parser = new DOMParser();
//     const resHTML = parser.parseFromString(res.data, "text/html");
//     const ajaxContent = resHTML.getElementById('search-results-ajax');
//     document.body.append(ajaxContent);
//     console.log(resHTML);
//   }).catch(err => {
//     console.log(err);
//   });
// }

function initResizeListener() {
  // Reference: http://www.html5rocks.com/en/tutorials/speed/animations/
  let ticking = false;

  window.addEventListener('resize', function(e) {
    if (!ticking) {
      window.requestAnimationFrame(function() {
        check();
        ticking = false;
      });

      ticking = true;
    }
  });
}

function check() {
  if (body.classList.contains(activeClass)) {
    show();
  }
}

function click(e) {
  const searchButton = e.target.closest('#search-button');
  if (!searchButton) {
    return;
  }
  e.preventDefault();
  toggle();
}

function show() {
  input.focus();
  body.classList.add(activeClass);
  const tl = gsap.timeline();
  tl.set(bar, {height: 'auto'})
  .from(bar, {
    height: 0,
    duration: 0.2,
    ease: 'power2.out',
    immediateRender: false
  });
}

function hide() {
  input.blur();
  body.classList.remove(activeClass);
  gsap.to(bar, {
    height: 0,
    duration: 0.1,
    ease: 'none'
  });
}

function toggle() {
  if (body.classList.contains(activeClass)) {
    hide();
  } else {
    dropdown.hide();
    mobile.hide();
    show();
  }
}

function init() {
  // document.addEventListener('submit', submit);
  document.addEventListener('click', click)
  initResizeListener();
}

const search = {
  init: init,
  hide: hide
}

export { search };
