import gsap from 'gsap';

const activeClass = 'home-hero-item-is-active';
const heroCards = Array.from(document.querySelectorAll('.js-home-hero-card'));

function initResizeListener() {
  // Reference: http://www.html5rocks.com/en/tutorials/speed/animations/
  let ticking = false;

  window.addEventListener('resize', function(e) {
    if (!ticking) {
      window.requestAnimationFrame(function() {
        check();
        ticking = false;
      });

      ticking = true;
    }
  });
}

function check() {
  const body = document.body;
  const viewport = window.innerWidth;
  if (viewport <= 960) {
    body.classList.add('home-hero-is-mobile');
  } else {
    body.classList.remove('home-hero-is-mobile');
  }
}

function animateImage(e) {
  const card = e.target;
  const parent = card.closest('.js-home-hero-item');
  const current = document.querySelector(`.${activeClass}`);

  if (current !== parent) {
    current.classList.remove(activeClass);
  }
  parent.classList.add(activeClass);
}

export default function() {
  if (!heroCards) {
    return;
  }
  heroCards.forEach(card => {
    card.addEventListener('mouseenter', animateImage);
  });
  initResizeListener();
}
