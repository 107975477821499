import page from './modules/page';
import { dropdown } from './modules/dropdown';
import { search } from './modules/search';
import { mobile } from './modules/mobile';
import home from './modules/home';
import filter from './modules/filter';
import carousel from './modules/carousel';
import video from './modules/video';

page();
dropdown.init();
search.init();
mobile.init();
home();
filter();
carousel();
video();

// if tab key is used, add class to show outline styles
function handleFirstTab(e) {
  if (e.keyCode === 9) {
    document.body.classList.add('user-is-tabbing');
    window.removeEventListener('keydown', handleFirstTab);
  }
}

window.addEventListener('keydown', handleFirstTab);
