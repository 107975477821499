import gsap from 'gsap';
import { search } from './search';

const buttonClass = 'submenu-button-is-active';
const menuClass = 'submenu-is-active';
const buttons = Array.from(document.querySelectorAll('.js-dropdown'));

function hide(menu) {
  // We might call this function when ajaxing pages so check if menu is defined
  // If not, find it
  let menuEl = menu || document.querySelector(`.${menuClass}`);
  if (!menuEl) {
    return;
  }
  menuEl.classList.remove(menuClass);
  gsap.to(menuEl, {
    height: 0,
    duration: 0.1,
    ease: 'none'
  });
}

function show(menu) {
  menu.classList.add(menuClass);
  const tl = gsap.timeline();
  tl.set(menu, {height: 'auto'})
  .from(menu, {
    height: 0,
    duration: 0.2,
    ease: 'power2.out',
    immediateRender: false
  });
}

function handle(e) {
  const button = e.currentTarget;
  const selector = button.dataset.menu;
  const menu = document.getElementById(selector);
  const activeMenu = document.querySelector(`.${menuClass}`);
  const activeButton = document.querySelector(`.${buttonClass}`);

  if (activeMenu) {
    if (activeMenu !== menu) {
      const tl = gsap.timeline();
      tl.call(() => {
        activeButton.classList.remove(buttonClass);
        button.classList.add(buttonClass);
        hide(activeMenu);
      })
      .call(() => {
        show(menu);
      }, null, '+=0.1');
    } else {
      activeButton.classList.remove(buttonClass);
      hide(activeMenu);
    }
  } else {
    button.classList.add(buttonClass);
    search.hide();
    show(menu);
  }
}

function init() {
  buttons.forEach(button => {
    button.addEventListener('click', handle);
  });
}

const dropdown = {
  init: init,
  hide: hide
}

export { dropdown };
