import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
import { dropdown } from './dropdown';
const body = document.body;

export default function() {
  body.classList.remove('no-js');

  window.addEventListener('pageshow', function(e) {
    dropdown.hide();
    const tl = gsap.timeline({delay: 0.1});
    tl.add('start');
    tl.to(body, {
      opacity: 1,
      duration: 0.1
    }, 'start')
    .add(function() {
      body.classList.remove('page-is-leaving', 'page-is-loading');
    }, 'start');
  });

  document.addEventListener('click', function(e) {
    const link = e.target.closest('a');
    if (!link || link.classList.contains('js-filter-paginate-link')) {
      return;
    }

    const target = link.getAttribute('target');
    const href = link.href;
    if (target === null || target === '_self' || target === '') {
      e.preventDefault();
      body.classList.add('page-is-leaving');
      gsap.to(body, {
        opacity: 0,
        duration: 0.1,
        onComplete: () => {
          window.location.href = href;
        }
      });
    }
  });

  ScrollTrigger.batch(['.js-st', '.js-st-content > *'], {
    once: true,
    onEnter: batch => gsap.to(batch, {
      duration: 0.5,
      delay: 0.2,
      stagger: 0.1,
      autoAlpha: 1,
      y: 0,
    })
  });
}
