// import barba from '@barba/core';
import axios from 'axios';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);
const filterSection = document.getElementById('filters');

function ajax(options) {
  const { url, paginate = false } = options;
  const body = document.body;
  // Add css class - temporarily disable further clicks on filters and cards
  body.classList.add('ajax-cards-is-loading');

  axios.get(url).then((res) => {
    history.pushState(url, null, url);
    const parser = new DOMParser();
    const resHTML = parser.parseFromString(res.data, "text/html");
    const newContainer = resHTML.getElementById('ajax-cards');
    const newContent = newContainer.innerHTML;
    const ajaxContainer = document.querySelector('#ajax-cards');
    ajaxContainer.innerHTML = newContent;

    ScrollTrigger.refresh(true);
    ScrollTrigger.batch('.js-st', {
      once: true,
      onEnter: batch => gsap.to(batch, {
        duration: 0.5,
        delay: 0.2,
        stagger: 0.1,
        autoAlpha: 1,
        y: 0,
      })
    });

    if (paginate) {
      gsap.to(window, {
        scrollTo: {
          y: filterSection,
          offsetY: () => {
            const header = document.getElementById('header');
            const headerHeight = header.offsetHeight;
            return headerHeight;
          }
        },
        duration: 0.2,
      })
    }
  }).catch((err) => {
    console.log(err);
  }).finally(() => {
    body.classList.remove('ajax-cards-is-loading');
  });
}

function updateFilters() {
  const checked = Array.from(document.querySelectorAll('.js-filter:checked'));
  // Always remove page number when updating filters
  // Updating the filters as per below allows for the presence of Craft's preview token - the 'filters' portion of the URL might start with a '?' or a '&'
  const currentUrl = new URL(window.location.href);
  const newPath = currentUrl.pathname.split('/page')[0];
  const newUrl = new URL(currentUrl.origin + newPath);
  const newSearchParams = new URLSearchParams(currentUrl.search);
  if (checked.length) {
    const values = checked.map(filter => filter.value);
    newSearchParams.set('filters', values);
    newUrl.search = newSearchParams.toString();
  }
  ajax({url: newUrl.href});
}

function resetFilters() {
  const filters = Array.from(document.querySelectorAll('.js-filter'));
  filters.forEach((filter) => filter.checked = false);
  // Always remove page number when updating filters
  const currentUrl = new URL(window.location.href);
  const newPath = currentUrl.pathname.split('/page')[0];
  const newUrl = new URL(currentUrl.origin + newPath);
  const newSearchParams = new URLSearchParams(currentUrl.search);
  // Only delete filters part of queryString, allows for presence of Craft token
  newSearchParams.delete('filters');
  newUrl.search = newSearchParams.toString();
  console.log(newUrl.href);
  // let url = href.includes('/page') ? href.split('/page')[0] : href.split('?filters')[0];
  ajax({url: newUrl.href});
}

function paginate(link) {
  const url = decodeURIComponent(link.href);
  ajax({
    url: url,
    paginate: true
  });
}

function handlePopState(e) {
  window.location.href = e.state;
}

export default function() {
  // TODO Move this history stuff to page.js
  // Record initial state so back button works when navigating all the way back
  history.replaceState(window.location.href, null, window.location.href);
  history.scrollRestoration = 'manual';
  window.addEventListener('popstate', handlePopState);

  document.addEventListener('click', function(e) {
    // Allow command-click and control-click to open new tab
    // http://henleyedition.com/allow-ctrl-command-click-using-event-preventdefault/
    if (event.metaKey || event.ctrlKey) {
      return;
    }
    const clicked = e.target;
    const paginateLink = clicked.closest('.js-filter-paginate-link');
    const resetButton = clicked.closest('#reset-filters');
    if (paginateLink) {
      e.preventDefault();
      paginate(paginateLink);
    } else if (resetButton) {
      resetFilters();
    }
  });

  document.addEventListener('change', function(e) {
    const target = e.target;
    const filter = target.closest('.js-filter');
    if (filter) {
      e.stopPropagation();
      updateFilters();
    }
  });
}
